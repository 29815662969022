import type { Customer } from "@chargebee/chargebee-js-types";
import { useAuth } from "~features/auth";
import { useSciAmJwtSync } from "~features/piano/hooks/use-piano-auth-sync";

/**
 * This is a quick way to derive a customer object from either the Auth0 or SciAm JWT, whichever is available.
 * @TODO: This is a temporary solution.
 */
export function useCustomerInfo() {
  const { user } = useAuth();
  const { payload } = useSciAmJwtSync();

  // If we don't have a user or payload, we can't derive a customer object
  if (!user && !payload) {
    return null;
  }

  // Setting the customer object lets us skip first step of the checkout process
  const customer = {
    email: user?.email || payload?.email,
    first_name:
      user?.["https://sciam.com/first_name"] ||
      payload?.["https://sciam.com/first_name"] ||
      user?.given_name ||
      payload?.given_name,
    last_name:
      user?.["https://sciam.com/last_name"] ||
      payload?.["https://sciam.com/last_name"] ||
      user?.family_name ||
      payload?.family_name,
  } as Customer;

  // Enable partial auto-fill for some billing address fields
  customer["billing_address"] = customer;

  return customer;
}
