/**
 * @file
 * This hook was taken and extended from Auth0's React example:
 * https://github.com/auth0/auth0-react/blob/6429fdd31959548906a2ee1ecc8ec4d3a137ebc3/examples/cra-react-router/src/use-api.ts
 *
 * Our version has the following changes:
 * - Auth0-environment-aware
 * - uses a different approach to modify the request headers (see fetchWithAuth)
 * - gets around a constraint where our WAF expects a specific Authorization header
 * - supports request cancellation
 * - supports re-fetching
 * - slightly better typedefs
 */

import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";

import { fetchWithAuth } from "../access-token";
import useAuth0Env from "./use-auth0-env";

export interface UseApiOptions extends RequestInit {
  /**
   * How to handle API authentication. Defaults to "prefer".
   * - "skip": Do not request an access token before fetching
   * - "prefer": Include an access token if available, but don't throw an error if it's not
   * - "require": Throw an error if an access token is not available
   */
  authMode?: "skip" | "prefer" | "require";

  /**
   * Whether the request should be made as soon as the component mounts or called manually.
   * Defaults to true.
   */
  immediate?: boolean;

  /** Defaults to "openid profile email" */
  scope?: string;
}

interface UseApiState<T = unknown> {
  error: Error | null;
  loading: boolean;
  data: T | null;
}

export interface UseApi<T = unknown> extends UseApiState<T> {
  /** Trigger a re-fetch of the data */
  update: () => void;

  /** Cancel the current fetch request */
  cancel: () => void;

  /**
   * A promise that resolves when the data is fetched
   *
   * This isn't necessary for the hook itself, but can be useful if
   * we choose to combine this with React Query or when we upgrade
   * to React 19 and make use of `use()` and `<Suspense />`
   */
  promise?: Promise<T | null>;
}

/**
 * Fetch data from an API using an Auth0 access token.
 *
 * Should this need any more complexity, we should migrate to React Query/TanStack Query.
 * @param url The URL to fetch data from.
 * @param options Additional options to pass to the fetch function and to configure the Auth0 access token.
 * @returns The state of the fetch operation, and functions to update and cancel the request.
 */
export const useApi = <T = any>(url: string | URL, options: UseApiOptions = {}): UseApi<T> => {
  // Auth0 Configuration
  const { slug: app_id } = useAuth0Env();
  const { getAccessTokenSilently } = useAuth0();

  // Extract non-fetch options
  const { immediate = true, authMode = "prefer", scope, ...fetchOptions } = options;

  // State
  const [state, setState] = useState<UseApiState<T>>({
    error: null,
    loading: immediate,
    data: null,
  });
  const [fetchIndex, setFetchIndex] = useState(0);
  const [abortController, setAbortController] = useState(() => new AbortController());
  const [promise, setPromise] = useState<Promise<T> | undefined>(undefined);

  // Actions
  const update = () => setFetchIndex((prev) => prev + 1);
  const cancel = () => abortController.abort();

  const fetchData = useCallback(async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);
    setState({ ...state, loading: true });

    try {
      // Get a fresh Auth0 ID token if needed
      const authToken = await fetchAuthToken(getAccessTokenSilently, authMode, app_id, scope);

      // Fetch the data.
      const res = await fetchWithAuth(url, { ...fetchOptions, signal }, authToken);
      const data = (await res.json()) as T;

      // Update the state with the new data
      setState({ ...state, data, error: null, loading: false });

      // Resolve promise with the data
      return data;

      // Errors can come from the Auth0 token, fetch request, or JSON parsing
    } catch (error: any) {
      if (error?.name === "AbortError") {
        // An Abort signal was called, meaning the request was cancelled intentionally
        setState({ ...state, loading: false });
        return null;
      }

      setState({ ...state, error, loading: false });
      console.error("[useApi] Failed to fetch data for URL:", url, error);

      // Re-throw the error so `promise` can catch it
      throw error;
    }
  }, [url, app_id]);

  // Fetch data on mount and when the fetchIndex changes
  useEffect(() => {
    // If immediate is false, don't fetch data on mount
    if (!fetchIndex && !immediate) return;
    setPromise(fetchData());

    // Cancel the request if the component is unmounted
    return cancel;
  }, [fetchIndex, immediate, fetchData]);

  return { ...state, update, cancel, promise };
};

/**
 * Fetch a fresh Auth0 ID token.
 * Extracted from fetchData() for readability purposes.
 * @returns {Promise<{ app_id: string, id_token?: string }>}
 */
async function fetchAuthToken(
  getTokenFn: Auth0ContextInterface["getAccessTokenSilently"],
  authMode: "skip" | "prefer" | "require",
  app_id: string,
  scope?: string,
) {
  if (authMode === "skip") return { app_id };

  // Get a fresh Auth0 ID token
  try {
    const { id_token } = await getTokenFn({
      detailedResponse: true,
      authorizationParams: scope ? { scope } : undefined,
    });

    return { app_id, id_token };
  } catch (error) {
    if (authMode === "require") throw error;
    console.warn("Failed to obtain fresh Auth0 ID token but continuing without it", error);
    return { app_id };
  }
}

export default useApi;
