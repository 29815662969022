export const log =
  typeof window !== "undefined" && window.location.search.match(/debug=entitlements/)
    ? console.debug.bind(window.console)
    : function () {};

/**
 * Check if a user has access to a specific resource.
 * @type {(resources: string[], matches: string[]) => boolean}
 */
export function compareEntitlements(entitlements = [], matches = []) {
  return entitlements?.some?.((item) => matches.includes(item)) || false;
}

/**
 * Get the highest entitlement for display and tracking purposes.
 *
 * @param {string[]} entitlements
 * @returns {{
 *  name: (string|undefined)
 *  id: (string|undefined)
 * }}
 */
export function getPrimaryEntitlement(entitlements) {
  const names = {
    UNLMTD: "Unlimited Subscription",
    DIGPRINT: "Print and Digital Subscription",
    DIGITAL: "Digital Subscription",
  };

  let primary = ["UNLMTD", "DIGPRINT", "DIGITAL"].filter((key) => entitlements.includes(key))[0];

  if (!primary) {
    return null;
  }

  return {
    name: names[primary],
    id: primary,
  };
}
