// @ts-check
import { createContext, useContext, useEffect, useState } from "react";

import {
  getPrimaryResource,
  getUserResourceList,
  hasInstitutionalAccess,
  resourceListIncludesRid,
} from "../../access";

import { usePiano } from "./use-piano";

/**
 * @typedef {import('../../access').Resource} Resource
 * @typedef {{
 *   hasAccess: boolean | undefined,
 *   hasSub: boolean | undefined,
 *   hasAdFree: boolean | undefined,
 *   hasIdpAccess: boolean | undefined,
 *   hasUnlimitedAccess: boolean | undefined,
 *   resources: Resource[],
 *   terms: Resource[], // @TODO: Replace all references to minamed "terms" property with "resources"
 *   primaryResource: Resource | null,
 *   checkAccess: () => Promise<Resource[]>
 * }} UserAccessState
 */

/** An SSR-safe user context */
const UserAccessContext = createContext(
  /** @type {UserAccessState} */ ({
    hasAccess: undefined,
    hasSub: undefined,
    hasAdFree: undefined,
    hasIdpAccess: undefined,
    hasUnlimitedAccess: undefined,
    terms: [], // deprecated, use resources
    resources: [],
    checkAccess: () => Promise.resolve([]),
  }),
);

export function ProvideUserAccess({ children }) {
  const userAccess = useProvideUserAccess();
  return <UserAccessContext.Provider value={userAccess}>{children}</UserAccessContext.Provider>;
}

export const useUserAccess = () => {
  return useContext(UserAccessContext);
};

const LAST_SEEN_ENTITLEMENTS_KEY = "_sciamLastSeenResources";

/**
 * Return last successfully recorded list of entitlements
 * @return {Resource[]} resources - array of resources
 */
function getLastSeenEntitlements() {
  let data = localStorage.getItem(LAST_SEEN_ENTITLEMENTS_KEY);
  if (!data) {
    return [];
  }
  try {
    return JSON.parse(data);
  } catch (e) {
    console.warn("[piano] Parsing recent entitlements failed", [e, data]);
    return [];
  }
}

/**
 * Record the resources as a comma separated list
 * @param {Resource[]} resources Array of resources
 */
function saveLastSeenEntitlements(resources) {
  try {
    localStorage.setItem(LAST_SEEN_ENTITLEMENTS_KEY, JSON.stringify(resources));
  } catch (e) {
    console.warn("[piano] access: could not store resources to localstorage", e);
  }
}

function clearLastSeenEntitlements() {
  localStorage.removeItem(LAST_SEEN_ENTITLEMENTS_KEY);
}

/**
 * User Access Hook
 * @TODO add SSR support
 * @TODO rename hasIdpAccess to hasInstitutionalAccess
 * @returns {UserAccessState} User access object. Undefined values indicate that we don't know the user's access status yet.
 */
function useProvideUserAccess() {
  const { isReady, authStatus } = usePiano();
  const [resources, setResources] = useState(/** @type {Resource[]} */ ([]));
  const [hasSub, setHasSub] = useState(undefined);
  const [hasAdFree, setHasAdFree] = useState(undefined);
  const [hasIdpAccess, setHasIdpAccess] = useState(undefined);
  const [hasUnlimitedAccess, setHasUnlimitedAccess] = useState(undefined);
  const [primaryResource, setPrimaryResource] = useState(() => getPrimaryResource(resources));
  const [clearLastSeenTimeout, setClearLastSeenTimeout] = useState(null);
  const hasAccess = hasIdpAccess || hasSub;

  // Check for IDP cookies
  useEffect(() => {
    function updateForInstitutionalAccess() {
      const instAccess = hasInstitutionalAccess();
      setHasIdpAccess(instAccess);
      if (instAccess) {
        setHasUnlimitedAccess(true);
      }
    }

    updateForInstitutionalAccess();
    window.addEventListener("ipd-update", updateForInstitutionalAccess);
    return () => {
      window.removeEventListener("idp-update", updateForInstitutionalAccess);
    };
  }, []);

  // Check for unlimited access when resources change
  useEffect(() => {
    if (!resources.length) return;
    setHasSub(resourceListIncludesRid(resources, "DIGITAL", "DIGPRINT", "UNLMTD"));
    setHasAdFree(resourceListIncludesRid(resources, "ADFREE", "UNLMTD"));
    setHasUnlimitedAccess(hasIdpAccess || resourceListIncludesRid(resources, "UNLMTD"));
    setPrimaryResource(getPrimaryResource(resources));
  }, [resources.map((r) => r.rid).join(",")]);

  /**
   * Fetch a new access list
   * @returns {Promise<Resource[]>} List of resources the user hass access to
   */
  async function checkAccess() {
    // If we're logged in, we can check for a subscription,
    // so we set the state to undefined to indicate we're loading
    if (authStatus === "logged-in" && hasSub === false) {
      setHasSub(undefined);
    }

    /** @type {Resource[]} */
    let resources = await getUserResourceList();
    if (resources.length) {
      // Save the most recent successful results
      saveLastSeenEntitlements(resources);

      // This might happen if user appears logged out to Piano for a moment
      if (clearLastSeenTimeout) {
        console.log("[piano] access: race condition detected");

        clearTimeout(clearLastSeenTimeout);
      }
    } else {
      // If we don't have any new resources, use the last successful results
      // This could cause users with expiring subscriptions to maintain access for a few extra days
      // if they stay signed into the same browser session.
      resources = getLastSeenEntitlements();
    }

    setResources(resources);
    setHasSub(resourceListIncludesRid(resources, "DIGITAL", "DIGPRINT", "UNLMTD"));
    setHasAdFree(resourceListIncludesRid(resources, "ADFREE", "UNLMTD"));
    setPrimaryResource(getPrimaryResource(resources));

    // If we don't have any terms or IDP, we definitely don't have unlimited access
    if (!resources?.length && !hasIdpAccess) setHasUnlimitedAccess(false);

    return resources;
  }

  // Check if user has a subscription
  useEffect(() => {
    // If we have IDP access, we don't need to check for a subscription
    // if (hasIdpAccess) return;
    // ...but it's good to check for user access terms anyway.

    // If we're still loading Piano SDK, we can't check for a subscription
    if (!isReady) {
      setHasSub(undefined);
      return;
    }

    // If auth is failing, lean on the most recent successful results.
    if (authStatus === "error") {
      let resources = getLastSeenEntitlements();
      setHasSub(resourceListIncludesRid(resources, "DIGITAL", "DIGPRINT", "UNLMTD"));
      setHasAdFree(resourceListIncludesRid(resources, "ADFREE", "UNLMTD"));
      setPrimaryResource(getPrimaryResource(resources));
    }

    // If we're still loading auth, we can't check for a subscription
    if (authStatus === "loading") return;

    // If we're logged out, we don't have a subscription
    if (authStatus === "logged-out") {
      setHasSub(false);
      setHasUnlimitedAccess(!!hasIdpAccess);
      setResources([]);

      // Clear out the last seen entitlements, but wait a sec
      // in case they a race condition blip
      const timeout = setTimeout(() => {
        clearLastSeenEntitlements();
      }, 10000);

      setClearLastSeenTimeout(timeout);
      return;
    }

    checkAccess();
  }, [isReady, authStatus]);

  return {
    hasAccess,
    hasSub,
    hasAdFree,
    hasIdpAccess,
    hasUnlimitedAccess,
    resources,
    terms: resources,
    primaryResource,
    checkAccess,
  };
}

export default useUserAccess;
