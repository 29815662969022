import type { ChargebeeInstance } from "@chargebee/chargebee-js-types";

import { appsBySlug } from "./environment";
import { chargebeeReady } from "./setup";

let instance: ChargebeeInstance;

/**
 * Initialize a Chargebee instance.
 * @param appSlug The app configuration slug to use for the instance (see `@sciam/chargebee` package)
 * @param force If true, create a new instance even if one already exists
 */
export function createChargebeeInstance(appSlug: string, force = false) {
  if (instance && !force) return instance;

  const { siteId, publishableKey } = appsBySlug[appSlug];
  window.Chargebee.init({
    site: siteId,
    publishableKey,

    /**
     * Enable Cart Customization feature
     * https://www.chargebee.com/checkout-portal-docs/drop-in-how-to.html#cart-customization
     *
     * tl;dr: Enables support for product catalog 2.0. We probably need this?
     */
    // @ts-ignore - Chargebee hasn't updated their typedefs in a while
    isItemsModel: true,
  });

  instance = window.Chargebee.getInstance();
  return instance;
}

/**
 * Wait for Chargebee's SDK to be loaded and ready before returning the instance.
 * If the instance is already created, this will resolve immediately.
 */
export async function getChargebeeInstance() {
  await chargebeeReady;
  return instance;
}
export const getChargebeeInstanceSync = () => instance;
