import "core-js/proposals/promise-with-resolvers";

import { useAuth0 } from "@auth0/auth0-react";
import type { GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js";
import { useEffect, useState } from "react";

import { useAuth0Env } from "~features/auth/hooks/use-auth0-env";
import { injectParamsInRequest } from "./utils/inject";

/**
 * Drop-in replacement for fetch that injects an Auth0 JWT and app ID into the request.
 *
 * @todo This can probably be done as a Request interceptor rather than a wrapper function.
 * @todo look into whether we can also pass in entitlements tokens in there too
 */
export async function fetchWithAuth(
  inputUrl: string | URL,
  options: RequestInit = {},
  auth0Token?: { app_id: string; id_token?: string },
) {
  const auth0Payload =
    // Use a provided token if available
    auth0Token ||
    // Otherwise wait for one from the global browser context
    (await Promise.race([
      // Get the access token from the Auth0 context.
      getAuth0Token(),
      // If the token is not resolved within 15 seconds, reject the promise.
      // This should never happen in in any reasonable scenario. On a plane maybe?
      new Promise<null>((reject) => setTimeout(() => reject(null), 60 * 1000)),
    ]));

  // Make sure we actually have a token to send
  // if (!auth0Payload?.id_token) throw new Error("No access token available");

  // Inject the access token and app ID into the request.
  const { url, body } = injectParamsInRequest({
    url: inputUrl,
    options,

    // Inject the access token and app ID into the request.
    params: {
      app_id: auth0Payload.app_id,
      access_token: auth0Payload.id_token,
    },
  });

  return fetch(url, {
    ...options,
    headers: {
      "X-Sciam": "node",
      ...options.headers,
    },
    body,
  });
}

//
// tl;dr:
// Auth0's React SDK is only meant to make requests from within a React component context.
// For fetchWithAuth() to be a portable drop-in replacement for fetch, we use the code
// below to extract Auth0 access tokens to a global context.
//

/** An Auth0 access token payload and its associated slug/app_id */
type AccessTokenPayload = GetTokenSilentlyVerboseResponse & { app_id: string };
const { promise, resolve, reject } = Promise.withResolvers<AccessTokenPayload>();

/**
 * Returns a promise that resolves to an access token from Auth0's React SDK.
 *
 * This pattern is roughly equivalent to python's `asyncio.Future`.
 *
 * @example // Contrived version of how this promise is used:
 * async function getAccountInfo() {
 *  const { id_token } = await tokenPromise;
 *  const response = await fetch("/api/account/?access_token=" + id_token);
 * }
 */
export const getAuth0Token = () => promise;

/**
 * Utility to fetch an access token from Auth0's React SDK.
 */
export function useProvideAccessToken() {
  const { slug } = useAuth0Env();
  const [tokenResponse, setTokenResponse] = useState<AccessTokenPayload>(null);
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  async function fetchToken() {
    try {
      const response = await getAccessTokenSilently({ detailedResponse: true });
      const payload = { app_id: slug, ...response };
      setTokenResponse(payload);

      // Save result to global context
      resolve(payload);
    } catch (error) {
      // Save error to global context
      reject(error);
    }
  }

  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) return reject(new Error("User is not authenticated"));
    fetchToken();
  }, [!isLoading]);

  return tokenResponse;
}

// This (hopefully) prevents Sentry from seeing this as an unhandled promise rejection
promise.catch(() => {});
