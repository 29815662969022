import { useEffect, useState } from "react";
import { usePageData } from "~core/hooks/use-page";
import Cookies from "js-cookie";
import { useEntitlements } from "~features/access";

function readJsonCookie(name) {
  try {
    return JSON.parse(Cookies.get(name));
  } catch (e) {
    return null;
  }
}

export function getBpid() {
  return (
    Cookies.get("idp_magic") ||
    Cookies.get("ezproxy_bpid") ||
    readJsonCookie("idp_details")?.BPID ||
    null
  );
}

/**
 * Record all the key institutional properties to a pixel
 * @param {object} dataLayerContent
 * @param {number} bpid
 * @param {boolean} hasInstitutionalAccess
 */
export function recordInstitutionalAccess(dataLayerContent, bpid, hasInstitutionalAccess) {
  // This function will pull from the dataLayer or page props and put them on a pixel
  const idpInstitutionalAccessGif = "/static/idp-institutional-access.gif";
  let trackingPixel = document.createElement("img");

  // Object of new params for image URL
  const additionalUrlParams = {
    debug: "idp",
    bpid: bpid,
    contentful_id: `${dataLayerContent.content.contentfulId}`,
    doi: `${dataLayerContent.content.articleDoi}`,
    url: window.location.pathname,
    hasInstitutionalAccess: hasInstitutionalAccess,
  };

  // Add params to image URL
  const newParams = new URLSearchParams([...Object.entries(additionalUrlParams)]).toString();
  let imgUrl = `${idpInstitutionalAccessGif}?${newParams}`;

  // Get the pixel off-screen so not to affect any other DOM elements
  trackingPixel.width = 0;
  trackingPixel.height = 0;
  trackingPixel.setAttribute("style", "position: absolute; left: -9999px");

  // Append pixel
  trackingPixel.src = `${imgUrl}`;

  return trackingPixel;
}

// Hook to initialize the tracking pixel
export function useTrackInstitutionalAccess() {
  // Does the user exist on the institutional list on this page?
  // Note that access can be date based, so its possible to be a BPID with access to one article and not another.
  const bpid = getBpid();
  // Does the user actually have institutional access to content?
  const { hasInstitutionalAccess } = useEntitlements();
  const [recordedInstAccessRecord, setRecordedInstAccessRecord] = useState(false);
  const { dataLayerContent } = usePageData();

  useEffect(() => {
    // If we've already recorded access or hasInstitutionalAccess is undefined, exit.
    // On initial render, hasInstitutionalAccess will be undefined, but once async communication sets hasInstitutionalAccess, it has to be true or false
    if (recordedInstAccessRecord || hasInstitutionalAccess === undefined) {
      return;
    }

    // If user has a BPID, then they are an institution.  Record that we have done this process already and pass relevant info
    // to the tracking pixel function
    if (bpid) {
      // Generate the tracking pixel
      let trackingPixel = recordInstitutionalAccess(dataLayerContent, bpid, hasInstitutionalAccess);
      // Append it to the DOM, since we are in React here.  Can't do it in the function as that is in Node, and has no
      // access to browser objects.
      document.body.appendChild(trackingPixel);
      setRecordedInstAccessRecord(true);
    }
  }, [hasInstitutionalAccess]);
}
