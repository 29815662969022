// @ts-check
import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useEffect, useState } from "react";

import { useAuth0Env } from "~/features/auth/hooks/use-auth0-env";

import {
  clearPianoAuthSyncToken,
  dispatchAuthSyncEvent,
  getPianoAuthSyncTokens,
  processToken,
  syncPianoJwt,
} from "../sync-utils";
import { useProvideSciAmJwtSync } from "./use-sciam-jwt-sync";

/**
 * @typedef {object} PianoAuthSyncContextValue
 * @property {"loading"|"ready"|"error"} status The status of the auth syncing process
 * @property {import("../sync-utils").PianoAuthSyncTokenOutput|null} currentToken The user's current Piano Auth Sync Token
 * @property {boolean} hasTokenUpdate Whether the Auth Sync Token has been updated, indicating a page refresh may be needed
 * @property {ReturnType<typeof getPianoAuthSyncTokens>|null} tokens All Piano Auth Sync Tokens
 * @property {(background?: boolean, useCachedJwt?: boolean) => Promise<void>} refresh Refresh the Piano Auth Sync JWT
 * @property {() => void} clear Clear the Piano Auth Sync JWT
 */

/** The grace period before expiration to refresh the Piano JWT in the background */
const EXPIRE_THRESHOLD = 24 * 60 * 60 * 60; // 60 days, or one third into the cookie's duration.

/**
 * Provides the Piano Auth Sync JWT to the app
 * @param {boolean} [force] Whether to force a refresh of the Piano JWT
 * @returns {PianoAuthSyncContextValue}
 */
export function useProvidePianoAuthSync(force = true) {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const { slug, clientId: aud } = useAuth0Env();

  // The status of the Piano Auth Sync JWT
  const [status, setStatus] = useState(/** @type {"loading"|"ready"|"error"} */ ("loading"));

  // Piano Auth Sync Tokens
  const [tokens, setTokens] = useState(
    /** @type {ReturnType<typeof getPianoAuthSyncTokens>|null} */ (null),
  );
  const [isExpiring, setIsExpiring] = useState(/** @type {boolean|null} */ (null));
  const [isExpired, setIsExpired] = useState(/** @type {boolean|null} */ (null));
  useEffect(() => {
    const authSyncTokens = getPianoAuthSyncTokens();
    setTokens(authSyncTokens);

    const currentToken = authSyncTokens?.[aud];
    if (currentToken) {
      setStatus("ready");

      // Check whether the expiration is within the threshold
      const { expires } = currentToken;
      const expired = expires < new Date();
      setIsExpired(expired);
      setIsExpiring(expired || expires < new Date(Date.now() + EXPIRE_THRESHOLD));
    } else {
      setIsExpiring(null);
    }
  }, []);

  /** The current Piano Auth Sync Token */
  const currentToken = tokens?.[aud] || null;

  /**
   * True when either:
   * 1. User is logged into Auth0 but a Piano JWT is missing
   * 2. The Piano JWT's "exp" claim is past the current time
   *
   * Technically #2 should never happen because the cookie expires at the same time as the JWT
   */
  const isTokenExpired = (isAuthenticated && !currentToken) || isExpired;

  /** Whether to refresh the Piano Auth Sync JWT  */
  const shouldRefresh =
    // Check whether the Piano JWT is missing, expiring, or we're forcing a refresh
    // We want to force a refresh on auth callback pages to ensure the Piano JWT is up-to-date before redirecting
    force || isTokenExpired || isExpiring;

  /** When a refresh isn't absolutely necessary, we can do it in the background and fail silently */
  const refreshInBackground = isExpiring && !isTokenExpired && !force;

  // Whether the Piano JWT has been updated
  // This can indicate to the user that they need to refresh the page
  const [hasTokenUpdate, setHasTokenUpdate] = useState(false);

  // Refresh the Piano JWT when the user logs in or out
  useEffect(() => {
    // Don't refresh the Piano JWT when it's not necessary or while Auth0 is still loading
    if (!shouldRefresh || isLoading) return;

    // Refresh the Piano JWT
    if (isAuthenticated) {
      refreshToken(refreshInBackground).then(dispatchAuthSyncEvent);
      return;
    }

    // User is logged out, clear the Piano JWT when the user logs out
    clearToken();
    dispatchAuthSyncEvent();
    setStatus("ready");
  }, [isAuthenticated, isLoading, shouldRefresh]);

  /**
   * Refresh the Piano JWT
   * @param {boolean} [background] Whether to refresh the Piano JWT in the background
   * @param {boolean} [cache] Whether to use Auth0's JWT cache or force a new token
   */
  async function refreshToken(background = false, cache = true) {
    setStatus("loading");

    try {
      console.log("[auth] [piano] refreshing Piano Auth Sync JWT");

      // Get the Auth0 JWT
      let auth0IdToken;
      const { id_token, expires_in } = await getAccessTokenSilently({
        // Use the cache to avoid unnecessary network requests
        // Disable cache if we need a new token (e.g., if a user changes their name)
        cacheMode: cache ? "on" : "off",
        detailedResponse: true,
      });
      auth0IdToken = id_token;

      // Retry without cache if the token is expired
      // expires_in is the time until expiry in seconds
      if (expires_in <= 0) {
        console.error("[auth] [piano] Auth0 JWT is expired. Retrying without cache.");
        // try fetching a new token
        const { id_token: newIdToken, expires_in } = await getAccessTokenSilently({
          cacheMode: "off",
          detailedResponse: true,
        });
        auth0IdToken = newIdToken;

        // This should never happen, but if it does, throw an error
        if (expires_in <= 0) {
          console.error("[auth] [piano] Auth0 JWT still expired after retry");
          throw new Error("Auth0 JWT is expired");
        }
      }

      // Get the new Piano Auth Sync JWT
      const pianoJwt = await syncPianoJwt(auth0IdToken, slug);

      // Update the hook state
      console.log("[auth] [piano] refreshed Piano Auth Sync JWT", pianoJwt);
      setTokens({
        ...tokens,
        [aud]: processToken(pianoJwt.token),
      });

      if (currentToken?.token !== pianoJwt.token) {
        setHasTokenUpdate(true);
      }

      setStatus("ready");
    } catch (error) {
      console.error("[auth] [piano] failed to refresh Piano Auth Sync JWT", error);
      // Background failures are silent
      if (background) {
        setStatus("ready");
      } else {
        // Clear the Piano JWT cookie on a realtime failure
        clearToken();
        setStatus("error");
      }
    }
  }

  /**
   * Clear the Piano JWT
   */
  function clearToken() {
    // Clear the Piano JWT cookie
    if (currentToken) {
      setHasTokenUpdate(true);
      clearPianoAuthSyncToken(aud);
    }

    // Remove the token from the state
    setTokens((prev) => {
      if (!prev) return prev;

      return {
        ...prev,
        [aud]: null,
      };
    });

    // Ensure Piano logs itself out. Cleanup any session data the Piano SDK may have.
    window.tp?.logout?.();
  }

  return {
    status,
    currentToken,
    hasTokenUpdate,
    tokens,
    refresh: refreshToken,
    clear: clearToken,
  };
}

// Here we fuse Piano and SciAM JWTs into a single context
// @TODO: Remove the Piano JWT when Chargebee is fully integrated

const noop = () => console.warn("method called before context was initialized");
const AuthSyncContext = createContext(
  /**
   * @type {{
   *   pianoAuthSync: PianoAuthSyncContextValue,
   *   sciamAuthSync: import("./use-sciam-jwt-sync").SciAmJwtSyncContextValue
   * }}
   * */ ({
    pianoAuthSync: {
      status: "loading",
      currentToken: null,
      tokens: null,
      hasTokenUpdate: false,
      clear: noop,
      refresh: noop,
    },
    sciamAuthSync: {
      token: null,
      stale: null,
      ready: false,
      check: noop,
      update: noop,
    },
  }),
);

/**
 * Sets up synchronizing the SciAm and Piano Auth Sync JWTs and provides them to the app context
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {boolean} [props.force] Whether to force an immediate refresh of the Piano Auth Sync token
 */
export const AuthSyncProvider = ({ children, force = false }) => {
  const pianoAuthSync = useProvidePianoAuthSync(force);
  const sciamAuthSync = useProvideSciAmJwtSync(force);

  return (
    <AuthSyncContext.Provider
      value={{
        pianoAuthSync,
        sciamAuthSync,
      }}
    >
      {children}
    </AuthSyncContext.Provider>
  );
};

export const usePianoAuthSync = () => {
  const context = useContext(AuthSyncContext);
  if (!context?.pianoAuthSync) {
    throw new Error("usePianoAuthSync must be used within a PianoAuthSyncProvider");
  }
  return context.pianoAuthSync;
};

export const useSciAmJwtSync = () => {
  const context = useContext(AuthSyncContext);
  if (!context?.sciamAuthSync) {
    throw new Error("usePianoAuthSync must be used within a PianoAuthSyncProvider");
  }
  return context.sciamAuthSync;
};

export default usePianoAuthSync;
