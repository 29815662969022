// import { appsBySlug, defaultAppSlug, defaultSiteId } from "~/features/chargebee/environment";
import { chargebeeReady, setupChargebee } from '~features/chargebee/setup';

const CHARGEBEE_JS_SDK_URL = "https://js.chargebee.com/v2/chargebee.js";

// @TODO: Add preload tags when we can write better logic for when we know a bundle needs it
/** @type {import('@sciam/shared').ServerScriptConfig} */
export const server = {
  preload: CHARGEBEE_JS_SDK_URL,
};

/** @type {import('@sciam/shared').ScriptConfig} */
export const client = {
  id: "sciads",
  src: CHARGEBEE_JS_SDK_URL,
  attrs() {
    // Set initial/default environment values for chargebeeEnv
    // chargebeeEnv = appsBySlug[defaultAppSlug];

    return {
      // The SDK is *very* heavy and we don't need it immediately
      async: true,
      defer: true,
      // This...might be optional. TBD
      // "data-cb-site": defaultSiteId,
    };
  },
  onLoad() {
    // Update environment value if necessary (e.g. query param override)
    // chargebeeEnv = getChargebeeEnv();
    setupChargebee();
  },
  readyWhen: chargebeeReady,
};
