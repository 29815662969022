import Cookies from "js-cookie";
import { jwtDecode } from 'jwt-decode';

/**
 * @typedef {{ value: any, raw: string }} CookieValue
 * @typedef {{ [key: string]: CookieValue }} PianoCookies
 * @typedef {{ added?: string[], removed?: string[], changed?: string[] }} CookieDiff
 */

const PIANO_COOKIES = [
  // Required cookies
  "xbc", // anonymous experience cookie
  "__tbc", // anonymous browser cookie
  "__utp", // user token cookie
  "__pid", // user data cookie
  "__idr", // user data cookie

  // Possibly not required?
  "__pil",
  "__eea",
  "__code",
  "piano-id-initial-gm-sso-shown-AID",
  "pnid-tc-string",
  /^p_.+/,
  /.+__ut$/,
  /.+__idr$/,
];

const isPianoCookie = (cookie) =>
  PIANO_COOKIES.some((pianoCookie) => {
    if (pianoCookie instanceof RegExp) {
      return pianoCookie.test(cookie);
    }
    return pianoCookie === cookie;
  });

/**
 * Use a custom cookie converter to parse Piano cookies
 * @type {Cookies.CookiesStatic<PianoCookies>}
 */
const pianoCookies = Cookies.withConverter({
  read: (value, name) => {
    // Ignore non-Piano cookies
    if (!isPianoCookie(name)) return undefined;

    // Try to parse the cookie as JSON or JWT
    try {
      return {
        value: JSON.parse(value),
        raw: value,
      };
    } catch (e) {
      return {
        value: jwtDecode(value) || value,
        raw: value,
      };
    }
  },
});

const diffCookies = (last, cur) => {
  /** @type {CookieDiff} */
  const diff = {};

  // Compare new cookies to the previous ones
  Object.keys(cur).forEach((key) => {
    // Ignore non-Piano cookies
    if (cur[key] === undefined) return;

    // Check for new cookies
    if (last[key] === undefined) {
      diff.added ??= [];
      diff.added.push(key);

      // Check for changes in the raw value
    } else if (cur[key].raw !== last[key].raw) {
      diff.changed ??= [];
      diff.changed.push(key);
    }
  });

  // Check for removed cookies
  Object.keys(last).forEach((key) => {
    if (last[key] !== undefined && cur[key] === undefined) {
      diff.removed ??= [];
      diff.removed.push(key);
    }
  });

  return diff;
};

/**
 * Watch for changes in Piano cookies
 * @param {(cookies: PianoCookies, diff: CookieDiff) => void} cb
 */
export function watchPianoCookies(cb, { initial = {}, immediate = true, interval = 1000 } = {}) {
  /** @type {PianoCookies} */
  let lastCookies = initial || {};

  const pollForPianoCookieChanges = () => {
    const cookies = pianoCookies.get();

    // Compare the current cookies to the previous ones
    const diff = diffCookies(lastCookies, cookies);

    // Cache the current cookies
    lastCookies = cookies;

    if (diff.added || diff.removed || diff.changed) {
      cb(cookies, diff);
    }
  };

  if (immediate) pollForPianoCookieChanges();
  // Watch for changes in cookies
  window.setInterval(pollForPianoCookieChanges, interval);

  return {
    update: pollForPianoCookieChanges,
    stop: () => {
      window.clearInterval(pollForPianoCookieChanges);
    },
  };
}
