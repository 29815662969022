import type { ChargebeeInstance, Customer } from "@chargebee/chargebee-js-types";
import { plans } from "@sciam/chargebee/plans.json";

/** Resolve a plan by its ID or its default price id */
function resolvePlan(planId: string) {
  return plans.find((plan) => plan.id === planId || plan.default_price_id === planId);
}

export interface CartItemOptions {
  /** A Chargebee Plan ID, e.g. `PRINTDIG_INT_OLD` */
  planId: string | null;
  /**
   * Optional billing period for the plan.
   * Only used when a plan can have multiple prices for differing period units
   */
  period?: "year" | "month" | "week" | "day" | "initial";
  /** Optional coupon code to apply to the checkout */
  couponCode?: string | null;
  /** Optional custom fields to pass to the checkout */
  customFields?: Record<string, any> | null;
  /** Optional, should almost only ever be `1` */
  quantity?: number;
  /**
   * Optional customer information.
   * @FIXME The Customer typedef is incorrect, use snake case (first_name) instead of camel case (firstName)
   */
  customer?: Customer;
}
export function addToCart(
  { planId, period, couponCode, customFields, quantity = 1, customer }: CartItemOptions,
  cbInstance: ChargebeeInstance,
) {
  const plan = resolvePlan(planId);
  if (!plan) throw new Error("Plan not found", { cause: planId });
  if (!plan.enabled_for_checkout) throw new Error("Plan not enabled for checkout");

  const price_id = plan.price[period]?.id || plan.default_price_id;
  const cart = cbInstance.getCart();
  const product = cbInstance.initializeProduct(price_id, quantity);

  if (couponCode) product.addCoupon(couponCode);
  if (customFields) product.setCustomData(customFields);

  if (customer) cart.setCustomer(customer);
  cart.replaceProduct(product);

  return {
    plan,
    product,
    cart,
  };
}

export function quickCheckout(options: CartItemOptions, cbInstance: ChargebeeInstance) {
  const { cart } = addToCart(options, cbInstance);
  cart.proceedToCheckout();
}

const LS_CB_CART_KEY = "sa_chargebee_cart";
export function saveCartState(item: CartItemOptions, checkout = false) {
  localStorage.setItem(
    LS_CB_CART_KEY,
    JSON.stringify({
      checkout,
      item,
      url: window.location.href,
      timestamp: Date.now(),
    }),
  );
}

export function restoreCartState(
  cbInstance: ChargebeeInstance,
  autoCheckout = false,
  customerInfo?: Customer,
) {
  const cartState = localStorage.getItem(LS_CB_CART_KEY);
  if (!cartState) return;

  const { item, url, checkout } = JSON.parse(cartState) as {
    item: CartItemOptions;
    url: string;
    checkout: boolean | undefined;
  };

  // Don't restore a cart if the item is missing
  if (!item) {
    localStorage.removeItem(LS_CB_CART_KEY);
    return;
  }

  // Don't checkout if the path has changed
  if (new URL(url).pathname !== window.location.pathname) return;

  // TODO: Check whether user already purchased the item
  // const alreadyPurchased = await checkIfAlreadyPurchased(item);

  const { cart } = addToCart({ ...item, customer: customerInfo || item.customer }, cbInstance);

  if (autoCheckout && checkout) {
    cart.proceedToCheckout();

    // TODO: Only remove the cart state if the checkout was successful
    localStorage.removeItem(LS_CB_CART_KEY);
  }

  return item;
}
