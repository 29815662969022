// @ts-check
import { jwtDecode } from 'jwt-decode';

/**
 * @typedef {import("@auth0/auth0-react").IdToken} IdToken
 * @typedef {{ token: string, expires: Date }} PianoAuthSyncTokenOutput
 */

/**
 * Gets all Piano JWTs from the user's cookies
 *
 * Every key represents a different Auth0 app/client id
 * @example output = { "949krH8...": { token: "eyJh...", expires: Date(...) } }
 */
export function getPianoAuthSyncTokens() {
  // Try to get the Piano JWT from our cookie
  const pianoAuthRe = /sa_piano_auth(?:\.(\w+))?=([^;]+)/g;
  let hasTokens = false;

  /** @type {Record<string, PianoAuthSyncTokenOutput>} */
  const authTokens = {};
  for (const [_, aud, token] of document.cookie.matchAll(pianoAuthRe)) {
    hasTokens = true;
    authTokens[aud || "default"] = processToken(token);
  }

  return hasTokens ? authTokens : null;
}

/** @param {string} clientId */
export const clearPianoAuthSyncToken = (clientId) => {
  document.cookie = `sa_piano_auth.${clientId}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

/** @returns {PianoAuthSyncTokenOutput} */
export const processToken = (token = "") => {
  const parsed = jwtDecode(token);
  return {
    token,
    expires: new Date(parsed?.exp * 1000),
  };
};

/** Promisified network idle */
export const networkIdle = (timeout = 7500) => {
  const waitForIdle = new Promise((resolve) => requestIdleCallback(resolve));
  const waitForTimeout = new Promise((resolve) => setTimeout(resolve, timeout));
  return Promise.race([waitForIdle, waitForTimeout]);
};

/**
 * When a user logs in, we send their Auth0 token to Django to get a JWT that Piano can use
 * This will be stored in a cookie and used to sync the user's Piano session
 */
export async function syncPianoJwt(idToken = "", auth0AppId = "") {
  // @FIXME: Using URL params for now because
  // 1. the WAF blocks use of the Authorization header
  // 2. using the body wasn't being parsed correctly in node for some reason
  // 2. Using another header and transforming the request via Express middleware wasn't working either
  //
  // However, this isn't ideal for security and PII
  return fetch(`/auth/piano/?access_token=${idToken}&app_id=${auth0AppId}`, {
    method: "POST",
    headers: {
      "User-Authorization": `Bearer ${idToken}`, // Redundant

      // This prevents our WAF from blocking the request
      // we should remove these headers once we know for sure they won't break non-production environments
      // "X-Temp-Patch-Authorization": `Bearer ${idToken}`,
      "X-Sciam": "node",
    },
    // body: JSON.stringify({ access_token: idToken }),
  }).then((res) => res.json());
}

/**
 * Tell the rest of the app that the Piano JWT has been updated
 *
 * On auth callback pages, this will trigger the onRedirectCallback function to perform the redirect
 */
export const dispatchAuthSyncEvent = () => window.dispatchEvent(new CustomEvent("auth-sync"));

/**
 * Wait for auth-sync event
 */
export const onPianoAuthSyncEvent = (callback) => {
  if (typeof window === "undefined") return;
  window.addEventListener("auth-sync", callback);

  return () => window.removeEventListener("auth-sync", callback);
};
