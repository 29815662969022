import { Reducer, useEffect, useReducer } from "react";

import type { ChargebeeInstance } from "@chargebee/chargebee-js-types";

export interface ChargebeeCheckoutContext {
  state: ChargebeeCheckoutState;
}
export function useProvideCheckout(instance: ChargebeeInstance): ChargebeeCheckoutContext {
  // Read more about React's useReducer hook here:
  // https://react.dev/learn/extracting-state-logic-into-a-reducer#comparing-usestate-and-usereducer
  const [state, dispatch] = useReducer(checkoutStateReducer, {
    status: "closed",
    step: null,
    error: null,
  });

  useEffect(() => {
    if (!instance) return;
    instance.setCheckoutCallbacks((cart) => ({
      close: () => dispatch({ type: "close" }),
      loaded: () => dispatch({ type: "loaded" }),
      success: (pageId) => dispatch({ type: "success", payload: pageId || true }),
      step: (payload: string) => dispatch({ type: "step", payload }),
      error: (payload: Error) => dispatch({ type: "error", payload }),
    }));
  }, [instance]);

  return {
    state,
  };
}

interface ChargebeeCheckoutState {
  status: "closed" | "loading" | "open" | "error";
  step?: string;
  /**
   * When defined, a checkout was successfully completed. Value will either be `true` or the page id
   * of the checkout success page.
   */
  success?: string | boolean;
  error: Error;
}

type CheckoutStateAction =
  | { type: "step"; payload: string }
  | { type: "error"; payload: Error }
  | { type: "success"; payload: string | boolean }
  | { type: "close"; payload?: string }
  | { type: string; payload?: undefined };

const checkoutStateReducer: Reducer<ChargebeeCheckoutState, CheckoutStateAction> = (
  prevState,
  action,
) => {
  // Dispatch global events
  // See `WindowEventHandlersEventMap` in frontend.d.ts for the full list of Chargebee events
  const { dispatchEvent } = window;
  dispatchEvent(new CustomEvent("chargebee:checkout", { detail: action }));
  dispatchEvent(new CustomEvent("chargebee:checkout-" + action.type, { detail: action.payload }));

  // Update React state
  switch (action.type) {
    case "open":
      return { ...prevState, status: "loading" };
    case "close":
      return { ...prevState, status: "closed" };
    case "success":
      return { ...prevState, status: "closed", step: null, success: action.payload };
    case "error":
      return { ...prevState, status: "error", error: action.payload };
    case "loaded":
      return { ...prevState, status: "open" };
    case "step":
      return { ...prevState, status: "open", step: action.payload };
    default:
      return prevState;
  }
};
