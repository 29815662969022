import { Reducer, useEffect, useReducer, useState } from "react";

import type {
  ChargebeeInstance,
  Portal,
  PortalCallbacks,
  PortalForwardOptions,
  PortalOpenSectionOptions,
} from "@chargebee/chargebee-js-types";

export interface ChargebeePortal extends Portal {
  /** An enum of portal sections available to the user. Null until Chargebee initializes. */
  sections: Record<string, string> | null;

  /** The current state of the portal. */
  state: ChargebeePortalState;

  // We wrap the open and openSection methods so React can follow loading state and errors
  // Also because Chargebee's typings kinda suck and we need to paper over them
  open: (
    options?: Partial<PortalCallbacks>,
    forwardOptions?: PortalForwardOptions,
  ) => Promise<boolean>;

  openSection: (
    options?: PortalOpenSectionOptions,
    callbacks?: Partial<PortalCallbacks>,
  ) => Promise<boolean>;
}

/**
 * A hook to provide a Chargebee portal instance to React components and synchronizes Chargebee's
 * portal state with our app's context
 *
 * This shouldn't be used directly. Instead, use `useChargebee().portal`
 */
export function useProvidePortal(
  instance: ChargebeeInstance,
  getPortal: () => Promise<Portal>,
): ChargebeePortal {
  // Read more about React's useReducer hook here:
  // https://react.dev/learn/extracting-state-logic-into-a-reducer#comparing-usestate-and-usereducer
  const [state, dispatch] = useReducer(portalStateReducer, {
    status: "closed",
    section: null,
    subscriptionUpdates: null,
    paymentUpdates: null,
  });

  const [sections, setSections] = useState<Record<string, string> | null>(null);
  useEffect(() => {
    if (!instance) return;

    // @ts-expect-error - Chargebee's typedefs are incomplete
    setSections(window.Chargebee.getPortalSections?.() || {});

    // One-off utility for creating subscription status change handlers
    const subChangeHandler = (status: SubscriptionStatus) => (data) =>
      dispatch({
        type: "update_subscription",
        payload: { [data?.subscription?.id || "unknown"]: status },
      });

    // Wire up portal state to React context
    instance.setPortalCallbacks({
      loaded: () => dispatch({ type: "open" }),
      close: () => dispatch({ type: "close" }),
      visit: (sectionType) => dispatch({ type: "open", payload: { section: sectionType } }),

      paymentSourceAdd: () => dispatch({ type: "update_payment", payload: "added" }),
      paymentSourceUpdate: () => dispatch({ type: "update_payment", payload: "changed" }),
      paymentSourceRemove: () => dispatch({ type: "update_payment", payload: "removed" }),

      subscriptionChanged: subChangeHandler("changed"),
      subscriptionCustomFieldsChanged: subChangeHandler("changed"),
      subscriptionCancelled: subChangeHandler("cancelled"),
      subscriptionResumed: subChangeHandler("resumed"),
      subscriptionPaused: subChangeHandler("paused"),
      scheduledPauseRemoved: subChangeHandler("resumed"),
      scheduledCancellationRemoved: subChangeHandler("resumed"),
      subscriptionReactivated: subChangeHandler("resumed"),
      subscriptionExtended: subChangeHandler("resumed"),
    } as PortalCallbacks);

    return () => {
      // @ts-expect-error - Chargebee's typedefs are wrong
      instance?.setPortalCallbacks?.({});
    };
  }, [instance]);

  return {
    sections,
    state,

    // We wrap the open and openSection methods so React can follow loading state and errors
    async open(options, forwardOptions) {
      dispatch({ type: "loading", payload: { section: null } });
      try {
        const cbPortal = await getPortal();
        // We cast the type here because Chargebee's typedefs think all callbacks are required
        cbPortal.open(options as PortalCallbacks, forwardOptions);
      } catch (error) {
        dispatch({ type: "error" });
        console.error("[chargebee] Error opening portal", error, { options, forwardOptions });
        return false;
      }
      return true;
    },

    async openSection(options, callbacks) {
      const section = options?.sectionType || null;
      dispatch({ type: "loading", payload: { section } });
      try {
        const cbPortal = await getPortal();
        // We cast the type here because Chargebee's typedefs think all callbacks are required
        cbPortal.openSection(options, callbacks as PortalCallbacks);
      } catch (error) {
        dispatch({ type: "error" });
        console.error("[chargebee] Error opening portal section", error);
        return false;
      }

      return true;
    },
  };
}

type SubscriptionStatus = "changed" | "paused" | "cancelled" | "resumed";
type PortalStatus = "loading" | "open" | "closed" | "error";

// The shape of the Chargebee portal state
type ChargebeePortalState = {
  status: PortalStatus;
  section: string | null;
  subscriptionUpdates: Record<string, SubscriptionStatus> | null;
  paymentUpdates: "changed" | "added" | "removed" | null;
};

// The kinds of actions that can be dispatched to change portal state
type ChargebeePortalAction =
  // Mutate portal state
  | { type: "open" | "loading"; payload?: Partial<ChargebeePortalState> }
  // Mutate subscription status
  | { type: "update_subscription"; payload: Record<string, SubscriptionStatus> }
  // Mutate payment status
  | { type: "update_payment"; payload: "changed" | "added" | "removed" }
  // Status-only actions
  | { type: "close" | "error"; payload?: unknown };
const portalStateReducer: Reducer<ChargebeePortalState, ChargebeePortalAction> = (
  state,
  action,
) => {
  // Dispatch global events
  // See `WindowEventHandlersEventMap` in frontend.d.ts for the full list of Chargebee events
  const { dispatchEvent } = window;
  dispatchEvent(new CustomEvent("chargebee:portal", { detail: action }));
  dispatchEvent(new CustomEvent("chargebee:portal-" + action.type, { detail: action.payload }));

  switch (action.type) {
    case "loading":
      return { ...state, status: "loading", ...action.payload };
    case "open":
      return { ...state, status: "open", ...action.payload };
    case "close":
      return { ...state, status: "closed" };
    case "error":
      return { ...state, status: "error" };
    case "update_subscription":
      return {
        ...state,
        subscriptionUpdates: {
          ...(state.subscriptionUpdates || {}),
          ...action.payload,
        },
      };
    case "update_payment":
      return { ...state, paymentUpdates: action.payload };
    default:
      return state;
  }
};
